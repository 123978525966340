body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    font-family: Akrobat, Arial, Helvetica, sans-serif;
    background-color: #ffffff;
    color: #000000;
}

#root {
    width: 100%;
    overflow: hidden;
}

.bm-burger-button span,
.bm-cross-button span,
.bm-menu .bm-item {
    transform: all 0.3s ease;
}

.bm-burger-button:hover span,
.bm-cross-button:hover span {
    background-color: #c6162c !important;
}

.bm-menu .bm-item:hover {
    color: #c6162c !important;
    text-decoration: none;
}